<template>
  <transition name="fade-in-up">
    <router-view></router-view>
  </transition>
</template>

<script>
import { SET_MENU } from '@/core/services/store/modules/menu.module';
import localData from '@/utils/saveDataToLocal';
import { SideBar } from '@/core/config/menu/sideBar';

export default {
  mounted() {
    this.$store.dispatch(SET_MENU, SideBar.BILL);
  },
  methods: {
    checkPermission: function(condition) {
      return localData.checkPermission(condition);
    }
  }
};
</script>

